import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Layout from "../components/Layout"


export default function PreEarthquake() {
  const currentyear = new Date().getFullYear();

  let yearlist = [];

  for (let i = 1900; i <= currentyear; i++) {
    yearlist.push(i);
  }
  const checkbox = async e => {
    let element = e.target;
    if(element.classList.contains("check_selected")){
      element.classList.remove("check_selected");
      element.previousSibling.checked = false;
    }else{
      element.classList.add("check_selected");
      element.previousSibling.checked = true;
    }
  }
  const updateYear = async e => {
    let design_code = document.getElementById("greek_design_code");
    let selectedyear = document.getElementById("construction_year").value;
    if(selectedyear <= 2009 ){
     design_code[1].setAttribute('hidden', 'hidden');
     design_code[0].selected = 'selected';
    }else{
      design_code[1].removeAttribute('hidden', 'hidden');
    }
  };

  const updateDesignCode = async e => {
    let design_code_value = document.getElementById("greek_design_code").value;
    let soil_cat = document.getElementById("soil_category_at_costruction_year");
    if(design_code_value === "ΕΑΚ2000"){
      soil_cat[4].setAttribute('hidden', 'hidden');
      soil_cat[4].selected = false;
    }else{
      soil_cat[4].removeAttribute('hidden', 'hidden');
    }
    
  };
  
  const uploadFile1 = async e => {
    const formData = new FormData();
    let file1 = document.getElementById("dir1-file");
    let button = document.getElementById("step-1");
    let errorspan = document.getElementById("step1error");
    formData.append('file1', file1.files[0], file1.files[0].name); 
    // create the request
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        file1.setAttribute('data-dir', xhr.responseText);
        file1.classList.remove("animateborder");
        let datafiles = button.getAttribute("data-files");
        if(datafiles === "1,2"){
          button.setAttribute("data-files", "2");
          errorspan.innerHTML = "Please upload direction 2 accelerogram file.";
        }else if(datafiles === "1"){
          button.setAttribute("data-files", "");
          errorspan.innerHTML = "Please upload direction 1 &amp; direction 2 accelerogram file.";
          errorspan.classList.add("hide");
          button.disabled = false;
          button.addEventListener("click", handleStep1);
        }
      }
    };
      // path to server would be where you'd normally post the form to
    xhr.open('POST', 'https://designerd.pythonanywhere.com/uploader', true);
    xhr.send(formData);
  };

  const uploadFile2 = async e => {
    const formData = new FormData();
    let file1 = document.getElementById("dir2-file");
    let button = document.getElementById("step-1");
    let errorspan = document.getElementById("step1error");
    formData.append('file1', file1.files[0], file1.files[0].name); 
    // create the request
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        file1.setAttribute('data-dir', xhr.responseText);
        file1.classList.remove("animateborder");
        let datafiles = button.getAttribute("data-files");
        if(datafiles === "1,2"){
          button.setAttribute("data-files", "1");
          errorspan.innerHTML = "Please upload direction 1 accelerogram file.";
        }else if(datafiles === "2"){
          button.setAttribute("data-files", "");
          errorspan.innerHTML = "Please upload direction 1 &amp; direction 2 accelerogram file.";
          errorspan.classList.add("hide");
          button.disabled = false;
          button.addEventListener("click", handleStep1);
        }
      }
    };
      // path to server would be where you'd normally post the form to
    xhr.open('POST', 'https://designerd.pythonanywhere.com/uploader', true);
    xhr.send(formData);
  };

  const handleStep1 = async e => {
    e.preventDefault();
    let spinner = document.getElementById("step1spinner");
    let images =  document.getElementById('step-1-images');
    images.classList.add('hide');
    let spectrums = document.getElementById('step-1-spectrums');
    let properties = document.getElementById('step-1-properties');
    let errorspan = document.getElementById("step1error");
    spectrums.classList.add('hide')
    properties.classList.add('hide')
    spinner.classList.remove("hide");
    errorspan.classList.add("hide");

    let filename1 = document.getElementById("dir1-file").getAttribute('data-dir');
    let filename2 = document.getElementById("dir2-file").getAttribute('data-dir');
    let file_format = document.getElementById("file_format").value;
    let scale_factor = document.getElementById("scale_factor").value;
    let time_step = document.getElementById("time_step").value;
    let units = document.getElementById("units").value;
    const formData = new FormData();
    formData.append('filename1', filename1);
    formData.append('filename2', filename2);
    formData.append('file_format', file_format);
    formData.append('scale_factor', scale_factor);
    formData.append('time_step', time_step);
    formData.append('units', units);

    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        let response = xhr.responseText;
        const imagesarray =  response.split("<br>");
        
        let image1 = document.getElementById('step1-image1');
        let image2 = document.getElementById('step1-image2');
        let image3 = document.getElementById('step1-image3');
        let image4 = document.getElementById('step1-image4');
        let image5 = document.getElementById('step1-image5');
        let aimage1 = document.getElementById('a-step1-image1');
        let aimage2 = document.getElementById('a-step1-image2');
        let aimage3 = document.getElementById('a-step1-image3');
        let aimage4 = document.getElementById('a-step1-image4');
        let aimage5 = document.getElementById('a-step1-image5');
        
        
        image1.src = imagesarray[0]
        image2.src = imagesarray[1]
        image3.src = imagesarray[2]
        image4.src = imagesarray[3]
        image5.src = imagesarray[4]
        aimage1.setAttribute("href", imagesarray[0]);
        aimage2.setAttribute("href", imagesarray[1]);
        aimage3.setAttribute("href", imagesarray[2]);
        aimage4.setAttribute("href", imagesarray[3]);
        aimage5.setAttribute("href", imagesarray[4]);

        let seismic_params_string = imagesarray[5]
        let seismic_params_array = seismic_params_string.split("|");
        console.log(seismic_params_array[0]);
        console.log(parseFloat(seismic_params_array[0]));
        console.log(parseFloat(seismic_params_array[0]).toFixed(4));
        document.getElementById("PGA").innerHTML = parseFloat(seismic_params_array[0]).toFixed(4);
        document.getElementById("PGV").innerHTML = parseFloat(seismic_params_array[1]).toFixed(4);
        document.getElementById("PGD").innerHTML = parseFloat(seismic_params_array[2]).toFixed(4);
        document.getElementById("PGVPGA").innerHTML = parseFloat(seismic_params_array[3]).toFixed(4);
        document.getElementById("arms").innerHTML = parseFloat(seismic_params_array[4]).toFixed(4);
        document.getElementById("vrms").innerHTML = parseFloat(seismic_params_array[5]).toFixed(4);
        document.getElementById("drms").innerHTML = parseFloat(seismic_params_array[6]).toFixed(4);
        document.getElementById("Ia").innerHTML = parseFloat(seismic_params_array[7]).toFixed(4);
        document.getElementById("Ic").innerHTML = parseFloat(seismic_params_array[8]).toFixed(4);
        document.getElementById("SED").innerHTML = parseFloat(seismic_params_array[9]).toFixed(4);
        document.getElementById("CAV").innerHTML = parseFloat(seismic_params_array[10]).toFixed(4);
        document.getElementById("ASI").innerHTML = parseFloat(seismic_params_array[11]).toFixed(4);
        document.getElementById("VSI").innerHTML = parseFloat(seismic_params_array[12]).toFixed(4);
        document.getElementById("HI").innerHTML = parseFloat(seismic_params_array[13]).toFixed(4);
        document.getElementById("EPA").innerHTML = parseFloat(seismic_params_array[14]).toFixed(4);
        document.getElementById("SMA").innerHTML = parseFloat(seismic_params_array[15]).toFixed(4);
        document.getElementById("PP").innerHTML = parseFloat(seismic_params_array[16]).toFixed(4);
        document.getElementById("TUD").innerHTML = parseFloat(seismic_params_array[17]).toFixed(4);
        document.getElementById("BRD").innerHTML = parseFloat(seismic_params_array[18]).toFixed(4);
        document.getElementById("SMD_TB").innerHTML = parseFloat(seismic_params_array[19]).toFixed(4);
        
        images.classList.remove('hide')
        spectrums.classList.remove('hide')
        properties.classList.remove('hide')
        spinner.classList.add("hide");
        errorspan.innerHTML = "Please scroll down in order to proceed to Step 2: ANN Structural Properties";
        errorspan.classList.remove("hide");
        errorspan.classList.add("step-success");
        document.getElementById('separator1').classList.remove('hide');
        document.getElementById('step2-header').classList.remove('hide');
        document.getElementById('step2-wrapper').classList.remove('hide');
        document.getElementById('step2-fields').classList.remove('hide');
        document.getElementById('step2-images').classList.remove('hide');
        document.getElementById('step2-button').classList.remove('hide');
      }
    };
      // path to server would be where you'd normally post the form to
    xhr.open('POST', 'https://designerd.pythonanywhere.com/', true);
    xhr.send(formData);
  
  };

  const handleStep2 = async e => {
    e.preventDefault();
    let spinner = document.getElementById("step2spinner");
    spinner.classList.remove("hide");
    
    let separator =  document.getElementById('separator2');
    separator.classList.add('hide');
    let step3header =  document.getElementById('step3-header');
    step3header.classList.add('hide');
    let step3wrapper =  document.getElementById('step3-wrapper');
    step3wrapper.classList.add('hide');
    let ann_spectrums = document.getElementById('ann_spectrums');
    let aann_spectrums = document.getElementById('a-ann_spectrums');
    ann_spectrums.classList.add('hide')

    let filename1 = document.getElementById("dir1-file").getAttribute('data-dir');
    let filename2 = document.getElementById("dir2-file").getAttribute('data-dir');
    let file_format = document.getElementById("file_format").value;
    let scale_factor = document.getElementById("scale_factor").value;
    let time_step = document.getElementById("time_step").value;
    let units = document.getElementById("units").value;
    
    let construction_year = document.getElementById("construction_year").value;
    let greek_design_code = document.getElementById("greek_design_code").value;
    let overground_storeys = document.getElementById("overground_storeys").value;
    let importance_at_construction_year = document.getElementById("importance_at_construction_year").value;
    let importance_according_to_eak = document.getElementById("importance_according_to_eak").value;
    let seismic_zone_at_construction_year = document.getElementById("seismic_zone_at_construction_year").value;
    let seismic_zone_according_to_eak = document.getElementById("seismic_zone_according_to_eak").value;
    let soil_category_according_to_eak = document.getElementById("soil_category_according_to_eak").value;
    let soil_category_at_costruction_year = document.getElementById("soil_category_at_costruction_year").value;
    let height_of_typical_storey = document.getElementById("height_of_typical_storey").value;
    let height_of_ground_storey = document.getElementById("height_of_ground_storey").value;
    let area_of_typical_storey = document.getElementById("area_of_typical_storey").value;
    let area_of_upper_storey = document.getElementById("area_of_upper_storey").value;
    let length_of_building_l1 = document.getElementById("length_of_building_l1").value;
    let length_of_building_l2 = document.getElementById("length_of_building_l2").value;
    let directions_of_rc_shear_walls = document.getElementById("directions_of_rc_shear_walls").value;
    let no_seismic_code = document.getElementById("no_seismic_code").value;

    if(!no_seismic_code){
      no_seismic_code = 'false';
    }
    let non_regularity_plan = document.getElementById("non_regularity_plan").value;
    if(!non_regularity_plan){
      non_regularity_plan = 'false';
    }
    let regular_masonries = document.getElementById("regular_masonries").value;
    if(!regular_masonries){
      regular_masonries = 'false';
    }

    const formData = new FormData();
    formData.append('filename1', filename1);
    formData.append('filename2', filename2);
    formData.append('file_format', file_format);
    formData.append('scale_factor', scale_factor);
    formData.append('time_step', time_step);
    formData.append('units', units);
    formData.append("construction_year", construction_year);
    formData.append("greek_design_code", greek_design_code);
    formData.append("overground_storeys", overground_storeys);
    formData.append("importance_at_construction_year", importance_at_construction_year);
    formData.append("importance_according_to_eak", importance_according_to_eak);
    formData.append("seismic_zone_at_construction_year", seismic_zone_at_construction_year);
    formData.append("seismic_zone_according_to_eak", seismic_zone_according_to_eak);
    formData.append("soil_category_according_to_eak", soil_category_according_to_eak);
    formData.append("soil_category_at_costruction_year", soil_category_at_costruction_year);
    formData.append("height_of_typical_storey", height_of_typical_storey);
    formData.append("height_of_ground_storey", height_of_ground_storey);
    formData.append("area_of_typical_storey", area_of_typical_storey);
    formData.append("area_of_upper_storey", area_of_upper_storey);
    formData.append("length_of_building_l1", length_of_building_l1);
    formData.append("length_of_building_l2", length_of_building_l2);
    formData.append("directions_of_rc_shear_walls", directions_of_rc_shear_walls);
    formData.append("no_seismic_code", no_seismic_code);
    formData.append("non_regularity_plan", non_regularity_plan);
    formData.append("regular_masonries", regular_masonries);

    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        let response = xhr.responseText;
        const responsearray =  response.split("<br>");
        
        let damage_level = responsearray[0];
        let ann_spectrums_image = responsearray[1];
        
        ann_spectrums.src = ann_spectrums_image;
        aann_spectrums.setAttribute("href", ann_spectrums_image);
        let resultspan = document.getElementsByClassName("resultspan")[0];
        let resultdescription = document.getElementsByClassName("resultdescription")[0];
        let resultdiv_all = document.getElementsByClassName("resultdiv");
        Array.from(resultdiv_all).forEach(function(resultdiv){
          //implement function operations
          resultdiv.classList.remove("active");
         });
         resultspan.classList.remove("result-null-slight");
         resultspan.classList.remove("result-moderate");
         resultspan.classList.remove("result-near-collapse");

        if(damage_level >= 0 && damage_level < 0.5){
          resultspan.innerHTML = "NULL-SLIGHT";
          resultspan.classList.add("result-null-slight");
          resultdescription.innerHTML = "NULL or SLIGHT damages are predicted for the examined building due to the selected seismic excitation. The most of these damages are expected to non-structural elements. Insignificant-no dangerous damages for the stability of the building are expected to structural elements.";
          document.getElementsByClassName('null-slight')[0].classList.add('active');
        }else if(damage_level >= 0.5 && damage_level < 1){
          resultspan.innerHTML = "MODERATE";
          resultspan.classList.add("result-moderate");
          document.getElementsByClassName('moderate')[0].classList.add('active');
          resultdescription.innerHTML = "MEDIUM damages are predicted for the examined building due to the selected seismic excitation. The damages are expected to structural and non-structural elements. These damages are repairable but the building must not be used by the occupants until the appropriate repairs are completed after a corresponding design of iterventions.";
        }else if(damage_level >=1){
          resultspan.innerHTML = "NEAR COLLAPSE";
          resultspan.classList.add("result-near-collapse");
          document.getElementsByClassName('near-collapse')[0].classList.add('active');
          resultdescription.innerHTML = "HIGH damages are predicted for the examined building due to the selected seismic excitation. These damages are significant and dangerous for the stability of the building. These damages are also considered as non-repairable. The building must be demolished. Special measures must be taken for the safety of the neighboring area.";
        }else {
          // not recoginizeable
        }

        separator.classList.remove('hide');
        step3header.classList.remove('hide');
        step3wrapper.classList.remove('hide');
        ann_spectrums.classList.remove('hide')
        
        spinner.classList.add("hide");
      }
    };
      // path to server would be where you'd normally post the form to
    xhr.open('POST', 'https://designerd.pythonanywhere.com/main', true);
    xhr.send(formData);
  
  };

  return (
    <Layout
      bodyClass="building-ai"
      heroTitle="Pre-Earthquake Damage Assessment Using Artificial Intelligence Algorithms"
      heroText=""
      bcText="TOOLS"
    >
      <section className="about-whoweare">
        <Container>
          <Row id="building-tools">
            <Col sm={12}>
              <div className="tools-box">
                <span className="red-subtitle">
                DESCRIPTION
                </span>
                <div className="icon-box">
                  <p>
                    {" "}
                    Assessment and damage level evaluation for building
                    inventories (minor, moderate, major, collapse) based on
                    pre-earthquake collected data.
                  </p>
                </div>
              </div>
              <div className="tools-box">
                <span className="red-subtitle">
                BUILDING AI
                </span>
                <div className="icon-box">
                  <h2>Step 1. Ground Motion Input</h2>
                </div>
                <div className="icon-box">
                  <Row>
                    <Col sm={12}>
                    <h3>Ground Motion Input Parameters</h3>
                    <div className="ai-inputs">
                      <div className="ai-input">
                        <label>FILE FORMAT</label>
                        <div className="select-wrapper">
                          <select id="file_format" defaultValue={"Two Columns"}>
                            <option value="PEER">PEER</option>
                            <option value="ESM">ESM</option>
                            <option value="One Column">ONE COLUMN</option>
                            <option value="Two Columns">TWO COLUMNS</option>
                          </select>
                        </div>
                      </div>
                      <div className="ai-input">
                        <label>SCALE FACTOR</label>
                        <input id="scale_factor" name="scale_factor" type="text" defaultValue="1.0" />
                      </div>
                      <div className="ai-input">
                        <label>TIME STEP</label>
                        <input id="time_step" className="input-disabled" defaultValue="0.0" type="text" />
                      </div>
                      <div className="ai-input">
                        <label>UNITS</label>
                        <div className="select-wrapper">
                          <select id="units" defaultValue={"cm"}>
                            <option value="mm">mm/s&sup2;</option>
                            <option value="cm">cm/s&sup2;</option>
                            <option value="m">m/s&sup2;</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    </Col>
                  </Row>
                  <Row className="border-bottom">
                    <Col sm={12} md={6}>
                      <h3>Direction 1 - Acceleration Time History Record</h3>
                      <div className="ai-inputs">
                        <div className="ai-input">
                          <label>LOAD ACCELEROGRAM FILE</label>
                          <input id="dir1-file" className="animateborder" onChange={uploadFile1} type="file"/>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={6}>
                      <h3>Direction 2 - Acceleration Time History Record</h3>
                      <div className="ai-inputs">
                        <div className="ai-input">
                          <label>LOAD ACCELEROGRAM FILE</label>
                          <input id="dir2-file" className="animateborder" onChange={uploadFile2} type="file"/>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                    <div className="ai-inputs">
                      <div className="ai-input button-input">
                        <button id="step-1" data-files="1,2" className="square-button red-button" disabled>CREATE SPECTRUMS <span className="fas fa-chevron-right"></span></button>
                        <span id="step1spinner" className="spinner hide"><img src="../images/half_circle.svg" alt="spinner"/></span>
                      </div>
                      <span id="step1error" className="step-error">Please upload direction 1 &amp; direction 2 accelerogram files.</span>
                    </div>
                    </Col>
                  </Row>
                  <Row id="step-1-images" className="border-bottom hide">
                    <Col sm={12} md={6}>
                     <h3>Direction 1 - Acceleration/Time Graph</h3>
                     <a id="a-step1-image1" target="_blank" href="../images/graph1.jpg"><img id="step1-image1" className="graph" src="../images/graph1.jpg" alt="Direction 1 - Acceleration/Time Graph" /></a>
                    </Col>
                    <Col sm={12} md={6}>
                     <h3>Direction 2 - Acceleration/Time Graph</h3>
                     <a id="a-step1-image2" target="_blank" href="../images/graph2.jpg"><img id="step1-image2" className="graph" src="../images/graph2.jpg" alt="Direction 2 - Acceleration/Time Graph"/></a>
                    </Col>
                  </Row>
                  <Row id="step-1-properties" className="hide">
                    <Col sm={12}>
                    <h3>Seismic Properties</h3>
                     <div className="seismic-params">
                       <div className="seismic-param-group">
                          <span>PGA = <span id="PGA"></span>g</span>
                          <span>PGV = <span id="PGV"></span>cm/s</span>
                          <span>PGD = <span id="PGD"></span>cm</span>
                          <span>PGV/PGA = <span id="PGVPGA">s</span></span>
                          <span>arms = <span id="arms"></span>g</span>
                          </div>
                       <div className="seismic-param-group">
                          <span>vrms = <span id="vrms"></span>cm/s</span>
                          <span>drms = <span id="drms"></span>cm</span>
                          <span>Ia = <span id="Ia"></span>m/s</span>
                          <span>Ic = <span id="Ic"></span></span>
                          <span>SED = <span id="SED"></span>cm<sup>2</sup>/s</span>
                          </div>
                       <div className="seismic-param-group">
                          <span>CAV = <span id="CAV"></span>cm/s</span>
                          <span>ASI = <span id="ASI"></span>g*s</span>
                          <span>VSI = <span id="VSI"></span>cm</span>
                          <span>HI = <span id="HI"></span>cm</span>
                          <span>EPA = <span id="EPA"></span>g</span>
                          </div>
                       <div className="seismic-param-group">
                          <span>SMA = <span id="SMA"></span>g</span>
                          <span>PP = <span id="PP"></span>s</span>
                          <span>TUD = <span id="TUD"></span>s</span>
                          <span>BRD = <span id="BRD"></span>s</span>
                          <span>SMD_TB = <span id="SMD_TB"></span>s</span>
                       </div>
                     </div>
                    </Col>
                  </Row>
                  <Row id="step-1-spectrums" className="hide">
                  <h3>Response Spectrums</h3>
                    <Col sm={12} md={6} lg={4}>
                      <a id="a-step1-image3" target="_blank" href="../images/graph3.jpg"><img id="step1-image3" className="graph" src="../images/graph3.jpg" alt="Response Spectrum"/></a>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <a id="a-step1-image4" target="_blank" href="../images/graph4.jpg"><img id="step1-image4" className="graph" src="../images/graph3.jpg" alt="Response Spectrum" /></a>
                    </Col>
                    <Col sm={12} md={6} lg={4}>
                      <a id="a-step1-image5" target="_blank" href="../images/graph5.jpg"><img id="step1-image5" className="graph" src="../images/graph3.jpg" alt="Response Spectrum" /></a>
                    </Col>
                  </Row>
                </div>
                <div id="separator1" className="vertical-separator hide"></div>
                <div id="step2-header" className="icon-box hide">
                  <h2>Step 2. ANN Structural Properties</h2>
                </div>
                <div id="step2-wrapper" className="icon-box hide">
                  <Row id="step2-fields" className="hide" >
                    <Col md={12} lg={6} className="column-fields">
                      <h3>Technical Data</h3>
                      <Row className="ann-inputs">
                        <Col sm={6}>
                        <div className="ai-input">
                          <label><span>CONSTRUCION YEAR</span></label>
                          <div className="select-wrapper">
                            <select id="construction_year" onChange={updateYear}>
                            {yearlist.map((optionyear,index)=>{
                              return(
                                <option key={index} value={optionyear}>{optionyear}</option>
                              )
                            })}
                            </select>
                          </div>
                        </div>
                        <div className="ai-input">
                          <label><span>GREEK DESIGN CODE</span></label>
                          <div className="select-wrapper">
                            <select id="greek_design_code" onChange={updateDesignCode}>
                             <option value="EAK 2000">EAK 2000</option>
                             <option value="EN1998-1">EN1998-1</option>
                            </select>
                          </div>
                        </div>
                        <div className="ai-input">
                          <label><span>Number of Overground Storeys</span></label>
                          <input id="overground_storeys" defaultValue="1.0" type="text" />
                        </div>
                        <div className="ai-input">
                          <label><span>Importance at Construction year</span></label>
                          <div className="select-wrapper">
                            <select id="importance_at_construction_year">
                             <option value="S1">S1</option>
                             <option value="S2">S2</option>
                             <option value="S3">S3</option>
                             <option value="S4">S4</option>
                            </select>
                          </div>
                        </div>
                        <div className="ai-input">
                          <label><span>Importance According to EAK 2000</span></label>
                          <div className="select-wrapper">
                            <select id="importance_according_to_eak">
                             <option value="S1">S1</option>
                             <option value="S2">S2</option>
                             <option value="S3">S3</option>
                             <option value="S4">S4</option>
                            </select>
                          </div>
                        </div>
                        </Col>
                        <Col sm={6}>
                        <div className="ai-input">
                          <label><span>Seismic Zone  at Construction year</span></label>
                          <div className="select-wrapper">
                            <select id="seismic_zone_at_construction_year">
                              <option value="1">I</option>
                              <option value="2">II</option>
                              <option value="3">III</option>
                            </select>
                          </div>
                        </div>
                        <div className="ai-input">
                          <label><span>Seismic Zone According to EAK 2000</span></label>
                          <div className="select-wrapper">
                            <select id="seismic_zone_according_to_eak">
                             <option value="1">I</option>
                             <option value="2">II</option>
                             <option value="3">III</option>
                            </select>
                          </div>
                        </div>
                        <div className="ai-input">
                          <label><span>Soil Category According to EAK 2000</span></label>
                          <div className="select-wrapper">
                            <select id="soil_category_according_to_eak">
                             <option value="A">A</option>
                             <option value="B">B</option>
                             <option value="C">C</option>
                             <option value="D">D</option>
                            </select>
                          </div>
                        </div>
                        <div className="ai-input">
                          <label><span>Soil Category at Construction Year</span></label>
                          <div className="select-wrapper">
                            <select id="soil_category_at_costruction_year">
                             <option value="A">A</option>
                             <option value="B">B</option>
                             <option value="C">C</option>
                             <option value="D">D</option>
                             <option value="E">E</option>
                            </select>
                          </div>
                        </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={12} lg={6} className="column-fields">
                    <h3>Input Features</h3>
                        <Row className="ann-inputs">
                          <Col sm={6}>
                            <div className="ai-input">
                              <label><span>Height of Typical Storey (m)</span></label>
                              <input id="height_of_typical_storey" defaultValue="3.0" type="text" />
                            </div>
                            <div className="ai-input">
                              <label><span>Height of Ground Storey (m)</span></label>
                              <input id="height_of_ground_storey" defaultValue="3.5" type="text" />
                            </div>
                            <div className="ai-input">
                              <label><span>Area of the Typical Storey (m<sup>2</sup>)</span></label>
                              <input id="area_of_typical_storey" defaultValue="100.0" type="text" />
                            </div>
                            <div className="ai-input">
                              <label><span>Area of the Upper Storey (m<sup>2</sup>)</span></label>
                              <input id="area_of_upper_storey" defaultValue="90.0" type="text" />
                            </div>
                            <div className="ai-input">
                              <label><span>Length of the Building L1 (m)</span></label>
                              <input id="length_of_building_l1" defaultValue="10.0" type="text" />
                            </div>
                          </Col>
                          <Col sm={6}>
                          <div className="ai-input">
                            <label><span>Length of the Building L2 (m)</span></label>
                            <input id="length_of_building_l2" defaultValue="10.0" type="text" />
                          </div>
                          <div className="ai-input">
                            <label><span>Directions of R/C Shear Walls</span></label>
                            <div className="select-wrapper">
                              <select id="directions_of_rc_shear_walls">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">1 &amp; 2</option>
                              </select>
                            </div>
                          </div>
                          <div className="ai-input checkbox">
                            <div>
                              <input id="no_seismic_code" type="checkbox" value="true" />
                              <span className="checkmark" onClick={checkbox}></span>
                            </div>
                            <label><span>Designed without Seismic Code</span></label>
                          </div>
                          <div className="ai-input checkbox">
                            <div>
                              <input id="non_regularity_plan" type="checkbox" value="true" />
                              <span className="checkmark" onClick={checkbox}></span>
                            </div>
                            <label><span>Non Regularity in Plan</span></label>
                          </div>
                          <div className="ai-input checkbox">
                            <div>
                              <input id="regular_masonries" type="checkbox" value="true" />
                              <span className="checkmark" onClick={checkbox}></span>
                            </div>
                            <label><span>Regular Masonries Distribution in Plan</span></label>
                          </div>
                          </Col>
                        </Row>
                    </Col>
                  </Row>
                  <Row id="step2-images" className="border-bottom hide">
                    <Col sm={12} md={6}>
                    <a target="_blank" href="../images/Fig1.svg"><img className="description_image" src="../images/Fig1.svg"  alt="ANN Structure Image 1"/></a>
                    </Col>
                    <Col sm={12} md={6}>
                    <a target="_blank" href="../images/Fig2.svg"><img className="description_image" src="../images/Fig2.svg"  alt="ANN Structure Image 2"/></a>
                    </Col>
                  </Row>
                  <Row id="step2-button" className="hide" >
                    <Col sm={12}>
                      <div className="ai-input button-input step2-button">
                        <button id="step-2" className="square-button red-button" onClick={handleStep2}>CALCULATE ANN DAMAGE LEVEL CLASSIFICATION <span className="fas fa-chevron-right"></span></button>
                        <span id="step2spinner" className="spinner hide"><img src="../images/half_circle.svg" alt="spinner"/></span>
                      </div>
                     
                    </Col>
                    <Col sm={12} md={6}>
                    <a id="a-ann_spectrums" target="_blank" href="../images/ann_spectrums.jpg"><img id="ann_spectrums" className="hide ann_spectrums" src="../images/ann_spectrums.jpg" alt="ANN Spectrums"/></a>
                    </Col>
                  </Row>
                </div>
                <div id="separator2" className="vertical-separator hide"></div>
                <div id="step3-header" className="icon-box hide">
                  <h2>Vulnerability Results</h2>
                </div>
                <div id="step3-wrapper" className="icon-box hide">
                  <Row>
                    <Col sm={12}>
                      <h3>ANN Damage Classification Using Max- Interstorey Drift Ration (MIDR)</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} lg={5}>
                      <div className="null-slight resultdiv">
                        <span>NULL-SLIGHT (0 &le; MIDR &lt; 0.5)</span>
                      </div>
                      <div className="moderate resultdiv">
                        <span>MODERATE (0.5 &le; MIDR &lt; 1.0)</span>
                      </div>
                      <div className="near-collapse resultdiv">
                        <span>NEAR COLLAPSE (MIDR &ge; 1.0)</span>
                      </div>
                    </Col>
                    <Col md={6} lg={7}>
                      <p className="resultp">Damage Level:<span className="resultspan"></span></p>
                      <p className="resultdescription">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris et euismod turpis, sit amet varius elit. Proin at ornare metus. Donec sollicitudin euismod lectus ut fermentum. Suspendisse eget lacinia tellus.</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
